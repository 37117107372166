import { useFetcher } from '@remix-run/react';
import * as Popover from '@radix-ui/react-popover';
import type { User } from '~/models/user.server';
import type { action } from '~/routes/project.$id._index';
import './project-card-actions.css';
import type { Project } from '~/models/project.server';
import { IconChevronDown } from '@fastly/beacon-icons';
import type { Playlist } from '~/models/playlist.server';

export type ProjectCardActionsProps = {
  featured?: boolean;
  pinned?: boolean;
  projectId: Project['id'];
  updateToast?: (message: string) => void;
  userId: User['id'];
  playlistId?: Playlist['id'];
};

const ProjectCardActions = ({
  featured,
  pinned,
  playlistId,
  projectId,
  userId,
}: ProjectCardActionsProps) => {
  const fetcher = useFetcher<typeof action>();

  return (
    <div className="project-card-actions">
      <Popover.Root>
        <Popover.Trigger asChild>
          <button
            className="project-card-actions-trigger"
            aria-label="Project actions"
          >
            <IconChevronDown />
          </button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className="project-card-actions-content"
            sideOffset={5}
          >
            <ul className="project-card-actions-buttons">
              {!featured && !playlistId && (
                <li>
                  <fetcher.Form method="post">
                    <button className="button-secondary" type="submit">
                      {pinned ? 'Remove pin' : 'Pin'}
                    </button>
                    <input
                      readOnly
                      hidden
                      name="intent"
                      value={pinned ? 'Unpin' : 'Pin'}
                    />
                    <input readOnly hidden name="userId" value={userId} />
                    <input readOnly hidden name="projectId" value={projectId} />
                  </fetcher.Form>
                </li>
              )}
              <li>
                <fetcher.Form method="post">
                  <button className="button-secondary" type="submit">
                    {featured ? 'Remove featured' : 'Feature'}
                  </button>
                  <input
                    readOnly
                    hidden
                    name="intent"
                    value={featured ? 'Unfeature' : 'Feature'}
                  />
                  <input readOnly hidden name="userId" value={userId} />
                  {playlistId && (
                    <input
                      readOnly
                      hidden
                      name="playlistId"
                      value={playlistId}
                    />
                  )}
                  <input readOnly hidden name="projectId" value={projectId} />
                </fetcher.Form>
              </li>
              <li>
                <fetcher.Form method="post">
                  <button className="button-secondary" type="submit">
                    {playlistId ? 'Remove from playlist' : 'Archive project'}
                  </button>
                  <input
                    readOnly
                    hidden
                    name="intent"
                    value={playlistId ? 'RemoveProject' : 'ArchiveProject'}
                  />
                  <input readOnly hidden name="userId" value={userId} />
                  {playlistId && (
                    <input
                      readOnly
                      hidden
                      name="playlistId"
                      value={playlistId}
                    />
                  )}
                  <input readOnly hidden name="projectId" value={projectId} />
                </fetcher.Form>
              </li>
            </ul>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

export default ProjectCardActions;
